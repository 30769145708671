.home-container {
  width: 100%;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  align-items: center;
  border-color: #9ba2bf;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-scheme-light-black);
}
.home-navbar {
  color: var(--dl-color-scheme-white);
  background-color: var(--dl-color-scheme-light-black);
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text {
  font-size: 20px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text01 {
  color: var(--dl-color-scheme-pink-cold);
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text02 {
  color: var(--dl-color-scheme-pink-cold);
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text03 {
  color: var(--dl-color-scheme-pink-cold);
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text04 {
  color: var(--dl-color-scheme-blue-dark);
}
.home-links {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-hero {
  background-color: var(--dl-color-scheme-light-black);
}
.home-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
}
.home-container02 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0px;
  flex-direction: column;
}
.home-image {
  width: 315px;
  height: 315px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-container03 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text05 {
  font-size: 80px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 800;
}
.home-text06 {
  color: var(--dl-color-scheme-blue);
}
.home-text07 {
  color: var(--dl-color-scheme-blue-dark);
}
.home-text08 {
  color: var(--dl-color-scheme-black);
}
.home-text09 {
  color: var(--dl-color-scheme-white);
}
.home-text11 {
  color: var(--dl-color-scheme-pink-cold);
}
.home-text12 {
  color: var(--dl-color-scheme-pink-cold);
}
.home-text13 {
  color: var(--dl-color-scheme-pink-cold);
}
.home-text15 {
  color: var(--dl-color-scheme-white);
}
.home-text16 {
  color: var(--dl-color-scheme-black);
}
.home-text17 {
  color: var(--dl-color-scheme-pink);
}
.home-text18 {
  color: var(--dl-color-scheme-pink);
}
.home-text19 {
  color: var(--dl-color-scheme-pink);
}
.home-text20 {
  color: var(--dl-color-scheme-blue);
}
.home-text21 {
  color: var(--dl-color-scheme-blue-dark);
}
.home-section {
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  justify-content: center;
  background-color: var(--dl-color-scheme-light-black);
}
.home-max-width1 {
  align-items: stretch;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
}
.home-container04 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: space-between;
  flex-direction: column;
}
.home-hero-image {
  width: auto;
  height: 645px;
  align-self: center;
  object-fit: cover;
  border-color: var(--dl-color-scheme-blue);
  border-width: 0px;
  border-radius: 36px;
}
.home-content {
  width: 555px;
  height: 739px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-text23 {
  color: var(--dl-color-scheme-blue-dark);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text24 {
  color: var(--dl-color-scheme-white);
  font-size: 42px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-step {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-pink-cold);
}
.home-text25 {
  color: var(--dl-color-scheme-white);
  font-size: 28px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
}
.home-container05 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title {
  color: #dc3b6c;
  font-size: 24px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text26 {
  color: var(--dl-color-scheme-white);
  font-family: Inter Tight;
  line-height: 1.5;
}
.home-step1 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-pink-cold);
}
.home-text27 {
  color: var(--dl-color-scheme-white);
  font-size: 28px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
}
.home-container06 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title1 {
  color: #dc3b6c;
  font-size: 24px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text28 {
  color: var(--dl-color-scheme-white);
  font-family: Inter Tight;
  line-height: 1.5;
}
.home-text30 {
  line-height: 1.5;
}
.home-step2 {
  display: flex;
  flex-direction: row;
}
.home-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-pink-cold);
}
.home-text32 {
  color: var(--dl-color-scheme-white);
  font-size: 28px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
}
.home-container07 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title2 {
  color: #dc3b6c;
  font-size: 24px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text33 {
  color: var(--dl-color-scheme-white);
  font-family: Inter Tight;
  line-height: 1.5;
}
.home-section1 {
  align-self: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-eightunits);
  padding-right: var(--dl-space-space-eightunits);
  justify-content: center;
  background-color: var(--dl-color-scheme-light-black);
}
.home-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-color: var(--dl-color-scheme-blue);
  border-width: 1px;
  border-radius: 48px;
}
.home-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.home-step3 {
  display: flex;
  margin-top: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number3 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-pink-cold);
}
.home-text34 {
  color: var(--dl-color-scheme-white);
  font-size: 28px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
}
.home-container10 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title3 {
  color: rgb(220, 59, 108);
  font-size: 24px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text35 {
  color: var(--dl-color-scheme-white);
  font-family: Inter Tight;
  line-height: 1.5;
}
.home-step4 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number4 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-pink-cold);
}
.home-text36 {
  color: var(--dl-color-scheme-white);
  font-size: 28px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
}
.home-container11 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title4 {
  color: rgb(220, 59, 108);
  font-size: 24px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text37 {
  color: var(--dl-color-scheme-white);
  font-family: Inter Tight;
  line-height: 1.5;
}
.home-step5 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number5 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-pink-cold);
}
.home-text38 {
  color: var(--dl-color-scheme-white);
  font-size: 28px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
}
.home-container12 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title5 {
  color: rgb(220, 59, 108);
  font-size: 24px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text39 {
  color: var(--dl-color-scheme-white);
  font-family: Inter Tight;
  line-height: 1.5;
}
.home-step6 {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number6 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-pink-cold);
}
.home-text40 {
  color: var(--dl-color-scheme-white);
  font-size: 28px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
}
.home-container13 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title6 {
  color: rgb(220, 59, 108);
  font-size: 24px;
  font-style: normal;
  font-family: Inter Tight;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text41 {
  color: var(--dl-color-scheme-white);
  font-family: Inter Tight;
  line-height: 1.5;
}
.home-hero1 {
  padding-top: 0px;
  background-color: var(--dl-color-scheme-light-black);
}
.home-max-width2 {
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
}
.home-content1 {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-title7 {
  color: var(--dl-color-scheme-pink-cold);
  font-size: 54px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-button {
  color: var(--dl-color-scheme-white);
  transition: 0.3s;
}

.home-image2 {
  height: 100%;
  position: relative;
  padding-bottom: 0px;
}
.home-hero-image1 {
  width: 385px;
  height: 843px;
  margin-top: 0px;
  margin-right: 0px;
  border-radius: 36px;
}
.home-footer {
  width: 100%;
  height: 76px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-scheme-light-black);
}
.home-links-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container15 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
@media(max-width: 991px) {
  .home-max-width1 {
    flex-direction: column;
  }
  .home-hero-image {
    width: 502px;
    height: 1109px;
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .home-content {
    width: 100%;
    height: 547px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
  }
  .home-text23 {
    align-self: center;
  }
  .home-text24 {
    align-self: center;
  }
  .home-section1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-container08 {
    width: 100%;
  }
  .home-image1 {
    width: 100%;
    height: 100%;
  }
  .home-container09 {
    width: 100%;
  }
  .home-content1 {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .home-image2 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-footer {
    align-items: center;
    flex-direction: column;
    background-color: var(--dl-color-scheme-light-black);
  }
  .home-links-container {
    width: 100%;
    margin-top: 0px;
    flex-direction: row;
    justify-content: center;
  }
  .home-container15 {
    align-items: center;
    justify-content: center;
  }
  .home-div1 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .home-links {
    display: none;
  }
  .home-container01 {
    align-items: center;
    flex-direction: column;
  }
  .home-container02 {
    width: 100%;
    padding-left: 0px;
  }
  .home-container03 {
    width: 100%;
    align-self: flex-end;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-text05 {
    width: 100%;
    font-size: 60px;
    align-self: center;
    margin-top: var(--dl-space-space-threeunits);
    text-align: center;
  }
  .home-hero-image {
    width: 371px;
    height: 793px;
  }
  .home-content {
    width: 100%;
    margin-top: var(--dl-space-space-threeunits);
    margin-right: 0px;
  }
  .home-section1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-container08 {
    width: 100%;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-image1 {
    width: 100%;
    height: 100%;
    padding-right: 4px;
  }
  .home-container09 {
    width: 100%;
    height: 100%;
  }
  .home-max-width2 {
    flex-direction: column-reverse;
  }
  .home-content1 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-title7 {
    font-size: 40px;
  }
  .home-hero-image1 {
    width: 100%;
    height: 760px;
    margin-right: 0px;
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    background-color: var(--dl-color-scheme-light-black);
  }
  .home-links-container {
    width: 100%;
    margin-top: 0px;
    flex-direction: row;
    justify-content: center;
  }
  .home-container15 {
    align-items: flex-end;
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-container01 {
    padding-right: 0px;
    flex-direction: column;
  }
  .home-container02 {
    width: 100%;
    padding-left: 0px;
  }
  .home-image {
    width: 250px;
    height: 250px;
  }
  .home-container03 {
    width: 100%;
  }
  .home-text05 {
    font-size: 40px;
    align-self: center;
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-section {
    padding-top: var(--dl-space-space-threeunits);
  }
  .home-max-width1 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-container04 {
    width: 479px;
    height: 100%;
    margin-top: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image {
    width: 295px;
    height: 570px;
    margin-top: 0px;
    margin-left: var(--dl-space-space-oneandhalfunits);
    margin-right: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-content {
    height: 100%;
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-text24 {
    font-size: 35px;
  }
  .home-title {
    font-size: 22px;
  }
  .home-text26 {
    font-size: 14px;
  }
  .home-title1 {
    font-size: 22px;
  }
  .home-text28 {
    font-size: 14px;
  }
  .home-title2 {
    font-size: 22px;
  }
  .home-text33 {
    font-size: 14px;
  }
  .home-section1 {
    width: 100%;
    height: 100%;
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-container08 {
    height: 100%;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
  }
  .home-image1 {
    width: 100%;
    height: 100%;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-container09 {
    width: 100%;
    height: 100%;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-title3 {
    font-size: 22px;
  }
  .home-text35 {
    font-size: 14px;
  }
  .home-title4 {
    font-size: 22px;
  }
  .home-text37 {
    font-size: 14px;
  }
  .home-title5 {
    font-size: 22px;
  }
  .home-text39 {
    font-size: 14px;
  }
  .home-title6 {
    font-size: 22px;
  }
  .home-text41 {
    font-size: 14px;
  }
  .home-max-width2 {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .home-title7 {
    font-size: 30px;
  }
  .home-description {
    font-size: 15px;
  }
  .home-image2 {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-hero-image1 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
  }
  .home-links-container {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}
